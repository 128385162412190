.topmenulink{
  color:#282c34 !important
}
.topmenulink:hover{
  color:#908f8f !important
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #eee;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #eee;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #000000;
}
.about-icon{
	vertical-align: middle;
	margin-right: 10 !important;
}

.site-header{
    position: sticky;
    left: 0;
    top: 0;
}